<script setup>
const reviews = ref([
  {
    name: 'Matheus',
    title: 'Experiência nota 10! Recomendo a todos.',
    message: 'Minha experiência com a Saúde Trevo foi excelente, fui muito bem atendido, tive enorme facilidade para conseguir marcar os exames que eu precisava e com muita rapidez.',
    avatar: 'https://web-cdn.saudetrevo.com.br/images/avatar5.png',
  },
  {
    name: 'A. G. Ramos',
    title: 'Satisfeita com o atendimento desde o primeiro contato!',
    message: 'Além de muito eficientes aqui no WhatsApp, sempre que preciso de ajuda com algum laboratório vocês resolver muuuito rápido!',
    avatar: 'https://web-cdn.saudetrevo.com.br/images/avatar4.png',
  },
  {
    name: 'Ana',
    title: 'Atendimento de qualidade!',
    message: 'A Saúde Trevo sempre me surpreende com o atendimento ágil e a preocupação com os pacientes.',
    avatar: 'https://web-cdn.saudetrevo.com.br/images/avatar3.png',
  },
  {
    name: 'L. M. Bezerra',
    title: 'Vocês estão de parabéns pelo atendimento!',
    message: 'Tanto no site quanto no laboratório, qualidade impecável. Já indiquei os serviços de vocês. Foi uma grande surpresa para mim, não conhecia.',
    avatar: 'https://web-cdn.saudetrevo.com.br/images/avatar2.png',
  },
])

const currentIndex = ref(0)

const currentReview = computed(() => reviews.value[currentIndex.value])

function prevReview() {
  currentIndex.value = (currentIndex.value - 1 + reviews.value.length) % reviews.value.length
}

function nextReview() {
  currentIndex.value = (currentIndex.value + 1) % reviews.value.length
}
</script>

<template>
  <section class="bg-secondary-50 py-10 md:py-20">
    <div class="container mx-auto px-5 lg:px-0 grid grid-cols-1 md:grid-cols-3 gap-6">
      <div>
        <div class="flex flex-row-reverse justify-center md:justify-end mb-4">
          <Img
            v-for="i in 4"
            :key="i"
            class="rounded-full size-14 -mr-3"
            :alt="`Avatar de depoimento ${i + 1}`"
            width="3.5rem"
            height="3.5rem"
            :src="`https://web-cdn.saudetrevo.com.br/images/avatar${i + 1}.png`"
            :style="`z-index: ${i}`"
          />
        </div>
        <h3 class="text-center md:text-left text-neutral-300 text-2xl font-sora font-semibold">
          O que a nossa<br />
          comunidade tem<br />
          a dizer?
        </h3>
      </div>
      <div class="col-span-2">
        <transition name="fade" mode="out-in">
          <div :key="currentReview.name">
            <Img
              :src="currentReview.avatar"
              :alt="currentReview.name"
              classes="rounded-full size-14 mb-2"
            />
            <p class="text-primary-400 text-base font-sora font-semibold mb-4">
              {{ currentReview.name }}
            </p>
            <h4 class="text-neutral-300 text-xl font-sora font-semibold mb-4">
              {{ currentReview.title }}
            </h4>
            <p class="text-neutral-100 text-xl mb-6">
              “{{ currentReview.message }}“
            </p>
          </div>
        </transition>
        <div class="flex gap-2">
          <span
            class="transition-all duration-500 ease-in-out flex justify-center items-center w-10 h-10
            bg-primary-500 hover:bg-neutral-200 rounded-full p-1.5 cursor-pointer"
            @click="prevReview"
          >
            <Icon name="mdi:chevron-left" class="text-base-50 size-full" />
          </span>
          <span
            class="transition-all duration-500 ease-in-out flex justify-center items-center w-10 h-10
            bg-primary-500 hover:bg-neutral-200 rounded-full p-1.5 cursor-pointer"
            @click="nextReview"
          >
            <Icon name="mdi:chevron-right" class="text-base-50 size-full" />
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
